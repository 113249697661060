import fetch from 'common/fetch';
// 获得订单列表数据/system/product/precise
export function modifyList(data) {
  return fetch({
    url: '/system/summary/list',
    method: 'post',
    data
  });
}

// 获得Change price list数据 /system/summary/{id}

export function orderDetails(id) {
  return fetch({
    url: '/system/summary/' + id,
    method: 'get'
  });
}

//updatePrice
export function updatePrices(data) {
  return fetch({
    url: '/system/summary/updatePrice',
    method: 'post',
    data
  });
}

// 正式订单
export function realList(data) {
  return fetch({
    url: '/system/summary/realList',
    method: 'post',
    data
  });
}

// ot订单列表

export function otList(data) {
  return fetch({
    url: '/system/summary/otList',
    method: 'post',
    data
  });
}

// ot 订单更新图片

export function otSubmit(data) {
  return fetch({
    url: '/system/summary/otSubmit',
    method: 'post',
    data
  });
}

// 查看转账信息
export function getOtInfo(data) {
  return fetch({
    url: '/pay/otInfo',
    method: 'post',
    data
  });
}