<template>
  <div class="modifyOrderPrice">
    <div class="modify">
      <el-form ref="form" :model="form" label-width="80px" :inline="true">
        <el-form-item label="Order No" class="form" prop="orderNo">
          <el-input
            v-model.trim.number="form.orderNo"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder=""
            :disabled="true"
            class="OrderPriceInput"
          />
        </el-form-item>
        <el-form-item
          label="Enterprise User"
          class="form"
          prop="userPin"
        >
          <el-input
            v-model.trim="form.userPin"
            placeholder=""
            :disabled="true"
            class="OrderPriceInput"
          />
        </el-form-item>
        <el-form-item label="Remarks" class="form" prop="remarks">
          <el-input
            v-model.trim="form.remarks"
            type="textarea"
            placeholder=""
            :disabled="true"
            class="OrderPriceInput"
          />
        </el-form-item>
      </el-form>
      <!-- 表格数据 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
        @select="select"
        @select-all="seletAll"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          type="selection"
          width="55"
          :selectable="checkSelectable"
        />
        <el-table-column prop="skuId" label="SKU ID" />
        <el-table-column prop="skuNumber" label="SKU Quantity" />
        <el-table-column
          prop="mallPrice"
          label="Single SKU Price"
          show-overflow-tooltip
        />
        <el-table-column label="Original Payment">
          <template slot-scope="scope">
            <span>{{
              scope.row.skuNumber *
                (scope.row.mallPrice - scope.row.productDiscount)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="false" label="Curren" prop="s">
          <template slot-scope="scope">
            <span>
              {{ scope.row.s }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Current Payment" prop="productAmount">
          <template slot-scope="scope">
            <span>
              {{ scope.row.productAmount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="Modified Discount">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.changePriceDiscount"
              type="text"
              oninput="value=value.replace(/[^\d]/g,'')"
              class="tableDataClass"
              size="mini"
              :disabled="scope.row.isChoose === false ? false : true"
              @input="handleChangeDiscount(scope.row, scope.$index)"
              @blur="handleChangeBlur(scope.row, scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改运费 -->
    <div class="multiple modify">
      <el-table
        ref="multipleTable"
        :data="freightData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
        @selection-change="freightSelectionChange"
        @select="freightSelect"
        @select-all="freightSelectAll"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          type="selection"
          width="55"
          :selectable="checkSelectable"
        />
        <!-- <el-table-column label="Carrier Code" prop="carrierCode" />
        <el-table-column prop="carrierName" label="Carrier Name" />
        <el-table-column v-if="false" label="Curren" prop="r">
          <template slot-scope="scope">
            <span>
              {{ scope.row.r }}
            </span>
          </template>
        </el-table-column> -->
        <!-- 以前的运费 -->
        <el-table-column prop="originalFreight" label="Original Freight Price">
          <template slot-scope="scope">
            {{ scope.row.originalFreight }}
          </template>
        </el-table-column>
        <!-- 修改后的运费 -->
        <el-table-column prop="changeFreightDiscount" label="Current Freight Price">
          <template slot-scope="scope">
            {{ scope.row.currentFreightPreight }}
          </template>
        </el-table-column>
        <el-table-column label=" Modify Freight Price">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.changeFreightDiscount"
              type="text"
              class="tableDataClass"
              size="mini"
              :disabled="scope.row.isChoose === false ? false : true"
              @input="handleChangeMoney(scope.row, scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 提交按钮 -->
    <div class="Submit">
      <el-button
        type="primary"
        class="el-icon-check"
        plain
        :disabled="disable"
        @click="submit"
      >
        Submit Modify
      </el-button>
    </div>
  </div>
</template>

<script>
import { orderDetails, updatePrices } from '@/api/modifys/modify';
export default {
  name: 'Modify',
  components: {},
  data() {
    return {
      emptyImgSrc: '',
      queryRow: null,
      form: {},
      disabled: false,
      tableData: [],
      tableDisabled: true,
      price: 0,
      freightData: [],
      // draftOrderSummary: [],
      change: true,
      productAmount: '',
      value1: null,
      disable: true
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(newName, oldName) {
        //将mounted中的数据在这里重新加载一下即可
        this.form = this.$route.query;
        this.orderDetailsr();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    //获取路由传参
    this.form = this.$route.query;
    if (this.$route.query.disable) {
      this.disable = true;
    } else {
      this.disable = false;
    }
    // this.orderDetailsr();
    this.checkSelectable();
  },
  methods: {
    seletAll(row) {
      this.tableData.forEach(item => {
        if (item.isChoose == true) {
          item.isChoose = false;
        } else if (item.isChoose == false) {
          item.isChoose = true;
        }
      });
    },
    select(selection, row) {
      if (row.isChoose) {
        row.isChoose = false;
      } else if (row.isChoose == false) {
        row.isChoose = true;
      }
    },
    freightSelect(selection, row) {
      row.isChoose = !row.isChoose;
    },
    freightSelectAll(selection, row) {
      this.freightData.forEach(item => {
        if (item.isChoose == true) {
          item.isChoose = false;
        } else if (item.isChoose == false) {
          item.isChoose = true;
        }
      });
    },
    freightSelectionChange(row) {},
    orderDetailsr() {
      orderDetails(this.form.orderId).then(res => {
        let array = [];
        if (res.success && res.code === 200) {
          if (res.data.orderSkuList && res.data.orderSkuList.length > 0) {
            res.data.orderSkuList.forEach(item => {
              item.isChoose = true;
              item.s = item.productAmount;
              // if (this.disable) {
                
              // }
              if (item.changePriceDiscount == null) {
                item.productAmount = item.skuNumber * (item.mallPrice - item.productDiscount);
              } else {
                item.productAmount =
                  item.skuNumber * (item.mallPrice - item.productDiscount) -
                  item.changePriceDiscount;
              }
              array.push(item);
            });
            this.tableData = array;
            this.freightData = [
              {
                originalFreight: res.data.originalFreight,
                changeFreightDiscount: res.data.changeFreightDiscount,
                currentFreightPreight: res.data.originalFreight - res.data.changeFreightDiscount,
                isChoose: true
              }
            ];
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }  
      });
    },
    submit() {
      let arrData = [];
      for(let item of this.tableData) {
        if (item.changePriceDiscount === '') {
          this.$message({
            type: 'error',
            message: 'Modified Discount cannot be empty'
          });
          return;
        }
        arrData.push({
          skuId: item.skuId,
          changeDiscountAmount: item.changePriceDiscount
        });
      }
      let obj = {
        orderId: this.form.orderId,
        changePriceSkuList: arrData,
        freightChangeDiscountAmount: this.freightData[0].changeFreightDiscount,
      }
      // if (this.freightData[0].changeFreightDiscount < 0) { 
      //   this.$message({
      //     type: 'error',
      //     message: 'The current price cannot be less than 0'
      //   });
      //   return;
      // }
      updatePrices(obj).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: 'Operation succeeded'
          });
          this.$router.push({path:'/modifyPriceManage/modifyPrice',query:{t:Date.now()}})
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 计算商品价格
    handleChangeDiscount(row, oldIndex) {
      if (row.changePriceDiscount == null) {
        row.productAmount = row.skuNumber * (row.mallPrice - row.productDiscount);
      } else {
        row.productAmount =
          row.skuNumber * (row.mallPrice - row.productDiscount) -
          row.changePriceDiscount;
        if (row.productAmount <= 0) {
          row.productAmount =
            row.skuNumber * (row.mallPrice - row.productDiscount);
          row.changePriceDiscount = 0;
          this.$message({
            type: 'error',
            message: 'The price cannot be negative'
          });
        }
      }
      
    },
    handleChangeBlur(row) {
      if (row.productAmount % row.skuNumber != 0) {
        row.changePriceDiscount = '';
        row.productAmount =
          row.skuNumber * (row.mallPrice - row.productDiscount) -
          row.changePriceDiscount;
        this.$message({
          type: 'error',
          message: 'Discount amount must be an integer multiple of SKU Quantity'
        });
        // return;
      }
    },
    handleChangeMoney(row, oldIndex) {
      let data = row.originalFreight - row.changeFreightDiscount;
      if (data <= 0) {
        this.$message({
          type: 'error',
          message: 'The current price cannot be less than 0'
        });
        row.changeFreightDiscount = 0;
        row.currentFreightPreight = row.originalFreight - row.changeFreightDiscount;
        return;
      } else {
        row.currentFreightPreight = row.originalFreight - row.changeFreightDiscount;
      }
    },
    checkSelectable(row) {
      if (this.disable == true) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.modifyOrderPrice {
  .modify {
    background: white;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 10px solid #eff0f4;
  }
  .OrderPriceInput {
    width: 400px;
  }
  .form {
    margin-left: 20px;
  }
  .el-form-item:nth-of-type(2) {
    /deep/.el-form-item__label {
      line-height: 20px !important;
    }
  }
  .el-form-item:nth-of-type(3) {
    .el-textarea__inner {
      min-width: 400px;
    }
  }
  .tableDataClass {
    outline: none;
  }
  .multiple {
    margin-top: 40px;
  }
  .Submit {
    //margin-left: 7px;
    background: white;
    width: 100%;
    height: 100px;
    /* position: fixed;
    bottom: 48px;
    z-index: 999; */
    line-height: 100px;
  }
}
</style>
<style lang="less">
.modifyOrderPrice {
  .Submit {
    .el-button {
      width: 200px;
      height: 40px;
      margin-left: 43%;
      padding: 0;
    }
  }
}
</style>
